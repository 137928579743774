@import url(https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap);
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 20px;
}

::selection {
  background: #2ddab8;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  background: #0c0f13;
  overflow: hidden;
  font-family: 'Sulphur Point', sans-serif;
  color: white;
  font-size: 0.9rem;
  cursor: url('https://raw.githubusercontent.com/chenglou/react-motion/master/demos/demo8-draggable-list/cursor.png') 39 39, auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 2.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
  cursor: pointer;
}

a {
  color: white;
  text-decoration: none;
}

